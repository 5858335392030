export default {
  "locale": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])},
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])}
  },
  "introduction": {
    "shortIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben een eerste jaars HBO-Verpleegkunde student, Jr. Software Engineer en Reservist bij de landmacht."])},
    "shortIntro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk mijn portfolio en werkervaring hieronder."])}
  },
  "aboutMeInCode": {
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.vaardigheden"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.scholing"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.CV"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.contactInfo"])},
    "interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.hobbies"])},
    "hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aannemen"])},
    "nigelResumeNPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigel/CV"])},
    "resumePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV.pdf"])},
    "outputSkills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, Vue, Javascript, HTML, CSS, Typescript, MySQL, NoSQL, Cypress"])},
    "outputInterests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airsoft, Motor rijden, Gamen"])},
    "outputEducation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een eerste jaars HBO-Verpleegkunde student aan de Hogeschool Van Amsterdam."])}
  },
  "projects": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Projecten"])},
    "linkToWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link naar website"])},
    "githubRepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github Repository"])},
    "agileBot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agile Discord bot"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een discord bot met een website dashboard."])}
    },
    "agileBotWeb": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agile Discord bot web dashboard"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een website dashboard voor de Agile bot, om te experimenteren met social login, OAUTH2 en het gebruik van de discord API I.C.M een bot."])}
    },
    "weatherApp": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weer app"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een weer app die de WeatherAPI en Tomorrow.io API gebruikt gemaakt met Vite en Spring Boot. Ik wilde dit project doen om meer ervaring op te doen met het gebruiken van APIs en het gebruiken van Spring boot als een rest backend."])},
      "webpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deprecated"])}
    },
    "discordApiNPM": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord API NPM"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een NPM package die ik heb gemaakt zodat je makkelijker met de discord API kan werken."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepubliceerd op NPM"])},
      "npmPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPM pagina"])}
    },
    "portfolioWebsite": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze website!"])}
    },
    "PEWA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Enterprise web application"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is een project die ik voor school hebt gemaakt."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het is een web applicatie voor de Gemeente Amsterdam in opdracht van de HvA."])},
      "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het project is gemaakt met een groep van 5 studenten."])},
      "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beschikbaar"])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op!"])}
  },
  "workExperience": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkervaring"])},
    "skills": {
      "collaborate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenwerken"])},
      "1-on-1 interviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-op-1 gesprekken"])},
      "presenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenteren"])},
      "speaking in front of groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreken voor groepen"])},
      "leadership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiding geven"])},
      "training staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opleiden van personeel"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])},
      "customer satisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanttevredenheid"])},
      "performing under pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presteren onder tijdsdruk"])},
      "problem solving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplossen van problemen"])},
      "stressResistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stressbestendig"])},
      "integrity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integer"])},
      "disciplined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discipline"])}
    },
    "picnic": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Runner Trainer"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picnic"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Runner Trainer ben ik onderdeel van het sollicitatietraject voor nieuwe kandidaten die intresse tonen om te komen werken als Runner bij Picnic. Ik ben verantwoordelijk voor de selectie en training van deze kandidaten door onderanderen het hebben van sollicitatie gesprekken en het geven van presentaties en natuurlijk trainingen geven in de praktijk. Daarnaast heb ik ook rondleidingen gedaan voor de brandweer en mensen van Groupil."])},
      "timeAtCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2023 - Januari 2025"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Runner"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben als runner verantwoordelijk voor het bezorgen van de boodschappen bij klanten. Dit moet op een redelijk strak schema, maar je neemt wel genoeg tijd om een goede indruk achter te laten bij de klanten. Verder zorg je er zelf voor dat je auto optijd is ingeladen en vertrekt."])},
      "timeAtCompany2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 2022 - Januari 2025"])}
    },
    "defensie": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservist Soldaat Verbindingsdienst"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landmacht - C2OstCo"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als soldaat reservist C2OstCo van de Landmacht ondersteun je bij het installeren, bedienen en onderhouden van alle Communicatie- en Informatiesystemen die bij de Landmacht in gebruik zijn. Denk hierbij aan computernetwerken en radio- satellietcommunicatie. Dit doe je parttime en is voornamelijk in de avond en in het weekend."])},
      "timeAtCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2024 - Heden"])}
    },
    "RYSST": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junior Software Engineer"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RYSST"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Junior Software Engineer bij RYSST ben ik verantwoordelijk voor het ontwikkelen van software voor klanten.Ik werk hier met een klein team van developers met de SCRUM methode."])},
      "timeAtCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maart 2024 - Heden"])}
    }
  }
}