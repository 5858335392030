import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "terminal" }
const _hoisted_3 = {
  class: "command",
  id: "overrideStyle"
}
const _hoisted_4 = { class: "output" }
const _hoisted_5 = { class: "command" }
const _hoisted_6 = { class: "output" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "command" }
const _hoisted_9 = { class: "output" }
const _hoisted_10 = { class: "command" }
const _hoisted_11 = { class: "output" }
const _hoisted_12 = { class: "command" }
const _hoisted_13 = {
  class: "command",
  id: "typingAnimation"
}
const _hoisted_14 = { class: "packageName" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "dots red" }, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "dots yellow" }, null, -1)),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "dots green" }, null, -1)),
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('aboutMeInCode.skills')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('aboutMeInCode.outputSkills')), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('aboutMeInCode.resume')), 1),
      _createElementVNode("p", _hoisted_6, [
        _createElementVNode("a", {
          href: _ctx.linkToResume,
          target: "_blank",
          "aria-label": "opens in new window"
        }, _toDisplayString(_ctx.$t('aboutMeInCode.resumePdf')), 9, _hoisted_7)
      ]),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('aboutMeInCode.interests')), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('aboutMeInCode.outputInterests')), 1),
      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('aboutMeInCode.education')), 1),
      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('aboutMeInCode.outputEducation')), 1),
      _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('aboutMeInCode.contactInfo')), 1),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "output" }, [
        _createElementVNode("a", { href: "mailto:email@nigelchristiaans.nl" }, "email@nigelchristiaans.nl"),
        _createTextVNode(", "),
        _createElementVNode("a", { href: "https://www.linkedin.com/in/n-christiaans/" }, "LinkedIn"),
        _createTextVNode(", "),
        _createElementVNode("a", { href: "https://github.com/nigel12341" }, "Github")
      ], -1)),
      _createElementVNode("p", _hoisted_13, [
        _cache[0] || (_cache[0] = _createTextVNode(" > ")),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "npm" }, "npm", -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('aboutMeInCode.hire')) + " ", 1),
        _createElementVNode("span", _hoisted_14, "@" + _toDisplayString(_ctx.$t('aboutMeInCode.nigelResumeNPM')), 1),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "blink" }, null, -1))
      ])
    ])
  ]))
}